<nav class="navbar navbar-expand-lg navbar-light fixed-top" id="mainNav">
  <div class="header-panel">
      <div class="logo-row">
         <img src="./assets/image/amawa_logo.png" style="width: 128px;">
         <h1>Amawa Ram Mandir, Ayodhya</h1>
      </div>
      <a href="javascript:void(0);" class="icon" (click)="showMenu=!showMenu">
       <i class="fa fa-bars"></i>
      </a>  
      <div class="topnav" [class.showMenu]="showMenu">
         <ul class="navbar-nav ml-auto my-2 my-lg-0">
            <li class="nav-item" role="presentation" routerLinkActive="active">
               <a class="nav-link js-scroll-trigger" routerLink="/home">Home</a>
            </li>
            <li class="nav-item" role="presentation" routerLinkActive="active">
               <a class="nav-link js-scroll-trigger" routerLink="/ram-rasoi">Ram Rasoi</a>
            </li>         
            <li class="nav-item" role="presentation" routerLinkActive="active">
               <a class="nav-link js-scroll-trigger" routerLink="/services">Rituals</a>
            </li>    
            <li class="nav-item" role="presentation" routerLinkActive="active">
               <a class="nav-link js-scroll-trigger" routerLink="/prasadam">Raghupati Laddo</a>
            </li>
            <li class="nav-item" role="presentation" routerLinkActive="active">
               <a class="nav-link js-scroll-trigger" routerLink="/gallery">Gallery</a>
            </li> 
            <li class="nav-item" role="presentation" routerLinkActive="active">
               <a class="nav-link js-scroll-trigger" routerLink="/testimonials">Testimonials</a>
            </li> 
            <li class="nav-item" role="presentation" routerLinkActive="active">
               <a class="nav-link js-scroll-trigger" routerLink="/contact-us">Contact Us</a>
            </li>
            <li class="nav-item" role="presentation" routerLinkActive="active">
               <a class="nav-link js-scroll-trigger" routerLink="/book-rasoi">Book Ram Rasoi</a>
            </li>
         </ul>
      </div>
   </div>
</nav>

<router-outlet></router-outlet>

<footer class="page-section footer" id="footer">
  <div class="container">
     <div class="row justify-content-center">
        <div class="col-lg-8 text-center">
           <h2 class="mt-0">Let's Get In Touch!</h2>
           <hr class="divider my-4">
           <p class="text-muted mb-5"></p>
        </div>
     </div>
     <div class="row">
      <div class="col-md-4">
         <div class="row">
            <div class="col-md-12 mb-sm">
               <h4>Other Attraction</h4>
            </div>
         </div>
         <div class="row">
            <div class="col-md-12">
               <ul class="list list-footer-nav">
                  <li><a href="http://smsns.org/">Shri Mahavir Sthan Nyas Samiti</a></li>
                  <li><a href="http://mahavirmandir.org/">Mahavir Mandir (English)</a></li>
                  <li><a href="http://mahavirmandirpatna.org/">Mahavir Mandir (Hindi)</a></li>
                  <li><a href="https://www.viraatramayanmandir.net/">Viraat Ramayan Mandir</a></li>                  
               </ul>
            </div>
         </div>
      </div>
      <div class="col-md-4">
          <div class="row">
             <div class="col-md-12 mb-sm">
                <h4>Nearby Attraction</h4>
             </div>
          </div>
          <div class="row">
             <div class="col-md-12">
                <ul class="list list-footer-nav">
                   <li>Ram janmbhoomi</li>
                   <li>Hanumangadhi</li>
                </ul>
             </div>
          </div>
       </div>
        <div class="col-md-4">
           <h4>Stay Connected</h4>
           <p><i class="fa fa-phone"></i>+91-94304 82958</p>
           <p><a href="mailto: amawarammandir@gmail.com"><i class="fa fa-envelope ml-sm"></i>	amawarammandir@gmail.com</a></p>
           <p><a href="https://www.facebook.com/amawarammandir" target="_blank"><i class="fa fa-facebook-square ml-sm"></i>View us on facebook</a></p>
           <!-- <p><a href="https://www.google.co.in/maps/place/Sri+Ram+Janki+Math/@25.6812488,85.2911137,16.25z/data=!4m5!3m4!1s0x39ed6734ea13e43d:0x9a9ca456bfb90471!8m2!3d25.6839788!4d85.2957943?hl=en"><i class="fa fa-map-marker ml-sm"></i>Map location</a></p> -->
        </div>
     </div>
     <div class="small text-center text-copyright">Copyright &copy; 2020 - Amawa Ram Mandir</div>
  </div>
</footer>
<!-- Footer -->

