 <!-- About Section -->
 <!-- <section class="page-section">
    <div class="container">
       <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
             <h2 class="mt-0">About The Temple</h2>
             <hr class="divider light my-4">
             <div class="slides">
               <div class="col-lg-4 col-md-6 col-xs-12">
                  <img src="./assets/image/amawa/amawa (1).jpg">
               </div>
               <div class="col-lg-8 col-md-6 col-xs-12">
                 <p>
                  {{aboutTemple}}  
                </p>
               </div>
             </div>
          </div>
       </div>
    </div>
  </section> -->
   <!-- About Section -->
 <section class="page-section">
    <div class="container">
       <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
             <h2 class="mt-0">Ram Rasoi</h2>
             <hr class="divider light my-4">
             <div class="slides">
               <div class="col-xs-12">
                 <p>
                    {{rasoidesc}}
                 </p>
                <a href="http://ramrasoi.org/">Click here to know more about Ram rasoi.</a>
               </div>
               <div class="gallery-wrapper">
                  <img src="./assets/image/rasoi-1.jpeg">
                  <img src="./assets/image/rasoi-2.jpeg">
               </div>
             </div>
          </div>
       </div>
    </div>
  </section>
