<!-- <section class="banner bg_cover" id="hero" style="background-image: url('../../assets/image/gallery/01.jpg');">
  <div class="container">
    <h1>Welcome to Amawa Ram Mandir Ayodhya.</h1>
  </div>
</section> -->

<!-- Masthead -->
<section class="masthead"  id="home">
  <!-- <div class="container h-100">
    <div class="row h-100 align-items-center justify-content-center text-center">
        <div class="col-lg-10 align-self-end">
          <h1 class="text-uppercase text-white font-weight-bold">Welcome to Amawa Ram Mandir</h1>
        </div>
    </div>
  </div> -->
  <ngb-carousel *ngIf="images" [showNavigationArrows]="true" [showNavigationIndicators]="true">
    <ng-template ngbSlide *ngFor="let image of images">
      <div class="picsum-img-wrapper">
        <img [src]="image" alt="Random slide">
      </div>
      <!-- <div class="carousel-caption">
        <h3>Welcome to Amawa Ram Mandir</h3>
      </div> -->
    </ng-template>
  </ngb-carousel>
</section>
<!-- About Section -->
<section class="page-section" id="about">
  <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12 text-center">
            <h2 class="mt-0">About Us</h2>
            <hr class="divider light my-4">
            <div class="slides">
              <div class="col-xs-12">
                <p>{{aboutTemple}}</p>
              </div>
            </div>
        </div>
      </div>
  </div>
</section> 
<!-- Services Section -->
<section class="page-section " id="services">
  <div class="container">
      <div class="row">
      <div class="col-xl-12 text-center">
        <h2 class="mt-0">Services</h2>
        <hr class="divider light my-4">
      </div> 

      <div class="col-12 data-row">
          <div class="card">
            <div class="card-header">
              <h4>Seva</h4>
            </div>      
            <div class="card-content">          
              <ul>
                  <li *ngFor="let service of services">
                    <a routerLink="/services">
                      <i class="fa fa-bell" aria-hidden="true"></i>
                      {{service}}</a>
                  </li>

              </ul>
            </div>
          </div>
          <!-- <div class="card">
            <div class="card-header">
              <h4>Upcoming Events</h4>
            </div>      
            <div class="card-content">          
              <ul>
                  <li class="calender-row">
                      <div class="date-box">
                          <span>May</span><br>
                          <p>10</p>
                          <span>2022</span>
                      </div>
                      <div>
                          <p>Ramayan Pratiyogita</p>
                      </div>
                  </li>
              </ul>
            </div>
          </div> -->
          <div class="card">
            <div class="card-header">
              <h4>Program</h4>
            </div>      
            <div class="card-content">          
              <ul>
                  <li><p><i class="fa fa-bell" aria-hidden="true"></i>Morning Archana</p></li>
                  <li><p><i class="fa fa-bell" aria-hidden="true"></i>Morning Bell</p></li>                    
                  <li><p><i class="fa fa-bell" aria-hidden="true"></i>Kirtan</p></li>
                  <li><p><a routerLink="/ram-rasoi"><i class="fa fa-bell" aria-hidden="true"></i>Ram Rasoi</a></p></li>
                  <li><p><i class="fa fa-bell" aria-hidden="true"></i>Evening Archana</p></li>
                  <li><p><i class="fa fa-bell" aria-hidden="true"></i>Ramayan Path</p></li>
              </ul>
            </div>
          </div>
    
        </div>
      </div>
  </div>
  
</section>