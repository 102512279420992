export class AppConfig {
  public static ABOUTTEMPLE = "Amawa Ram Mandir in Ramkot at Ayodhya is situated at the same sight whereupon stood the temple of the Raghu dynasty wherein Emperors of the Raghu clan including Lord Ram used to worship the deities. This is the reason that the Amawa Mandir, which in the complex of 15 temples, houses all the important deities of the Hindu religion. The word Amawa denotes the place situated in old Patna District and now in Nalanda District of Bihar. The Rani of Amawa built this temple in 1919 AD. It was renovated and extended by Acharya Kishore Kunal, a former IPS officer, Vice Chancellor of K.S.D. Sanskrit University and ex-Chairman of Bihar State Board of Hindu Religious Trusts. He built a magnificent Ram Temple with tuffen glass all around. A very fascinating idol of Lord Ram in childhood form was installed on 8th November 2019 with an earnest request and resolve that litigation of Ram Janmbhoomi may be decided in favour of Ram Lallaji at the earliest. The earnest request was heard by the Lord of the Universe and on the very next day i.e. 9th November 2019 the case, which was pending for almost 70 years was decided in favour of Ram Lalla. This Ram temple on the first floor of the extended portion of the Amawa Temple is the first Air - Conditioned temple of the holy town of Ayodhya and the deity is overlooking all the pilgrims going towards the Ram Janmbhoomi through the tuffen glasses. Since his installation in the temple last year, the Amawa Mandir has become the talk of the town and is now known throughout the country because of many factors including Ram Rasoi."
  public static RAMRASOI = "Ram Rasoi is run by the famous Mahavir (Hanuman) Mandir of Patna. All the expenses for running Ram Rasoi are borne by the Patna Mahavir Mandir. Amawa Ram Mandir has provided all infrastructural facilities to run the Ram Rasoi. It has provided Kitchen, dining hall, all utensils, electricity, water etc. to facilitate the successful running of Ram Rasoi. Around One thousand pilgrims coming from all parts of the country are provided free delicious meal at Amawa Mandir during daytime from 11:00 AM to 3:30 PM every day since its auspicious commencement on 1st December 2019, which was Vivah Panchmi festival. For running such a project, which is gigantic in nature and which requires enormous fund, neither Mahavir Mandir Trust, Patna nor Amawa Mandir, Ayodhya accepts anything in cash or kind. Therefore, no devotee should send any money or anything in cash or kind for this purpose because It shall not be accepted. Mahavir Mandir, Patna has allotted Rs. Three Crore in its annual budget for running Ram Rasoi.";
  public static RITUALS = "Now the Amawa Ram Mandir is starting many rituals from the Maha Shivarati  day i.e.11th  March 2021. Amawa Ram Mandir is situated adjacent to Ram Janmbhoomi and since it has temples of all the the important dities and since it is the most spacious temple with huge surroundings. No other Temple at Ayodhya except Hanumangarhi and Ram Janmbhoomi is as suitable for rituals as the Amawa Ram Mandir. Therefore, devotees are requested to be associated with Amawa Ram Mandir for performing their rituals. Fees for those who will be conducting rituals by physical presence in the temple premises fee will be different from those who will get them performed on online booking. Those who come to the temple for rituals their fees will be indicated there. But for those who get the rituals performed by online booking the following are the fees.";
  public static PRASADAM = "Raghupati Laddoo Prasadam is another attraction attached to Amawa Ram Mandir. Made of pure cow-ghee and prepared by skilled persons from Tirupati its taste is fantastic and matchless. One lakh and twenty five thousand (1,25,000) Raghupati Laddoos had been distributed by the renowned Patna Mahavir Mandir on the occasion of the Bhoomi-pujan of the Ram Temple at Ayodhya. At Ayodhya Amawa Mandir is providing the Raghupati Laddoo Prasadam to devotees at the rate of Rs 300/- per kg. Now it is available on online at website. It will be sent to the devotees through postal service or courier as per their request. Those who visit Ayodhya must taste the utterly delicious Raghupati Laddoos which is the epitome of purity and perfection in preparing the superb Laddoos. Pilgrims should make their visit memorable by these Raghupati Laddoos. And those who are not in a position to come to Ayodhya immediately, they should order for receiving these Laddoos which are Prasadam, as they will be sent to their after offering these Laddoos to the deity Bal Roop Ram which was installed with all the rituals of pran-pratishtha last year.";
  public static RITUALSLIST = [
    {
      name: 'Ramarcha',
      price: '5000'
    },
    {
      name: 'Rudrabhishek',
      price: '2500'
    },
    {
      name: 'Satya Narayan Katha',
      price: '2500'
    },
    {
      name: 'Maha Mritunjay Japa',
      price: '1800 (Per Thousand)'
    },
    {
      name: 'Sundarkand recital (Tulasi)',
      price: '1100'
    },
    {
      name: 'Sundarkand recital (Valmiki)',
      price: '2700'
    },
    {
      name: 'Hanuman Kavachan',
      price: '1100 (It will take 9 days)'
    },
    {
      name: 'Durga Saptashati',
      price: '1500'
    },
    {
      name: 'Gopal Stavana',
      price: '1500'
    },
    {
      name: 'Aditya Hridaya',
      price: '700'
    },
    {
      name: 'Hawan with Vedic hymns',
      price: '1500'
    },
    {
      name: 'Vardhapan Sanskar',
      price: '1800'
    }
  ];
  public static SERVICES = [
    'Divy-Arpan And Karmakand',
    'Pooja-Archana',
    'Jap And Path',
    'Vahan-Pooja',
    'Kundali Nirman',
    'Jyotish Paramarsh'
  ]
}
export class Gallery{
  public static HOMEPAGEIMAGES = [
    './assets/image/gallery/H09.jpg',
    './assets/image/gallery/H02.jpg',
    './assets/image/gallery/H03.jpg',
    './assets/image/gallery/H04.jpg',
    './assets/image/gallery/H05.jpg',
    './assets/image/gallery/H06.jpg',
    './assets/image/gallery/05.jpg',
    './assets/image/gallery/09.jpg',
    './assets/image/gallery/01.jpg',
    './assets/image/gallery/03.jpg'
  ];
  public static GALLERYIMAGES = [
    {
      src : './assets/image/gallery/09.jpg',
      description : 'Main Lord Shiva Temple'
    },
    {
      src : './assets/image/gallery/12.jpg',
      description : 'Second Shiva Temple'
    },
    {
      src : './assets/image/gallery/H08.jpg',
      description : 'Third Shiva Temple - This is the temple meant for Jalabhisheka and Rudrabhisheka'
    },
    {
      src : './assets/image/gallery/H06.jpg',
      description : 'Traditional Ram Temple'
    },
    {
      src : './assets/image/gallery/H02.jpg',
      description : 'Ram Darbar with Lav and Kush in the lap of Ram and Sita'
    },
    {
      src : './assets/image/gallery/H09.jpg',
      description : 'Newly stalled Ram in Baal Roop. The idol is so attractive that eyes always tend to concentrate on it.'
    },
    {
      src : './assets/image/gallery/08.jpg',
      description : 'Durga Mandir - Goddess Durga is represented in all forms in this temple in marvelous marble'
    },
    {
      src : './assets/image/gallery/H03.jpg',
      description : 'Shri Vishnu Idol'
    },
    {
      src : './assets/image/gallery/H04.jpg',
      description : 'Shri Hanuman Idol'
    },
    {
      src : './assets/image/gallery/H05.jpg',
      description : 'Shri Ganesh Idol'
    },
  ];
}