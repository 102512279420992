 <!-- About Section -->
 <section class="page-section">
    <div class="container">
       <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
             <h2 class="mt-0">Contact Us</h2>
             <hr class="divider light my-4">
             <div class="row">
                <div class="col-lg-4 col-md-4 text-center">
                   <div class="mt-5">
                      <i class="fa fa-4x fa-address-card mb-4"></i>
                      <h3 class="h4 mb-2">Address</h3>
                      <p class="text-muted mb-0">Ramkot
                        Ayodhya, Uttar Pradesh, India 224123</p>
                   </div>
                </div>
                <div class="col-lg-4 col-md-4 text-center">
                   <div class="mt-5">
                      <i class="fa fa-4x fa-envelope-open mb-4"></i>
                      <h3 class="h4 mb-2">Email</h3>
                      <p class="text-muted mb-0">amawarammandir@gmail.com</p>
                   </div>
                </div>
                <div class="col-lg-4 col-md-4 text-center">
                   <div class="mt-5">
                      <i class="fa fa-4x fa-mobile mb-4"></i>
                      <h3 class="h4 mb-2">Phone</h3>
                      <p class="text-muted mb-0">91-9430482958</p>
                      <p class="text-muted mb-0">91-9770969843</p>
                   </div>
                </div>
             </div>
           </div>
       </div>
    </div>
  </section>
   <!-- About Section -->