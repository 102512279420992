<!-- Gallery Section -->
<section class="page-section" id="gallery">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <h2 class="mt-0">Gallery</h2>
        <hr class="divider light my-4">
        <div class="row">
          <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 image-box" *ngFor="let item of gallaryList">
            <div class="thumbnail">
              <img [src]="item.src" alt="{{item.src}}" class="img-rounded" style="width:100%">
              <div class="caption">
                <div class="caption-text">{{item.description}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>