<section class="page-section " id="services">
  <div class="container">
    <div class="row">
      <div class="col-xl-12 text-center">
        <h2 class="mt-0">RITUALS</h2>
        <hr class="divider light my-4">
        <div class="slides">
          <div class="col-lg-12 col-md-12">
            <p>
              {{ritualDescription}}
            </p>
          </div>
          <div class="col-lg-12 col-md-12">
            <div class="card">
              <div class="card-header">
                <h4>Divy-Arpan And Karmakand</h4>
              </div>
              <div class="card-content">
                <ul class="ritual-list">
                  <li *ngFor="let ritual of rituals">
                    <p class="link">{{ritual.name}}</p>
                    <div class="value">₹ {{ritual.price}}</div>
                    <button (click)="open(ritual,content)">Order</button>
                  </li>
                </ul>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
</section>
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Thanks </h4>
    <!--<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>-->
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row" *ngIf="modalType == 'mail'">
        <div class="col-md-12" *ngIf="selectedRitual">
          <div class="well well-sm">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="name">
                    Name</label>
                  <input type="text" class="form-control" id="name" [(ngModel)]="mailRequest.name" placeholder="Enter name"
                    required="required" />
                </div>
                <div class="form-group">
                  <label for="name">
                    Phone</label>
                  <input type="number" class="form-control" id="phone" [(ngModel)]="mailRequest.phone" placeholder="Phone Number"
                    required="required" />
                </div>
                <div class="form-group">
                  <label for="email">
                    Email Address</label>
                  <div class="input-group">
                    <span class="input-group-addon"><span class="glyphicon glyphicon-envelope"></span>
                    </span>
                    <input type="email" [(ngModel)]="mailRequest.email" class="form-control" id="email" placeholder="Enter email"
                      required="required"/>
                  </div>
                </div>
                <div class="form-group" style="display: none;">
                  <label for="subject">
                    Ritual</label>
                    <input type="text" [(ngModel)]="mailRequest.subject" class="form-control" id="subject" placeholder="Enter ritual"
                      required="required"/>
                </div>
              </div>
              <div class="col-md-12" style="display: none;">
                <div class="form-group">
                    <label for="name">
                        Message</label>
                    <textarea [(ngModel)]="mailRequest.message" id="message" class="form-control" rows="2" cols="25" required="required"
                        placeholder="Message"></textarea>
                </div>
            </div>
              <div class="col-md-12">
                <button  [disabled]="!mailRequest.name || !mailRequest.phone || !mailRequest.email"  
                (click)="submitForm()"  class="btn btn-primary pull-right" id="btnContactUs">
                  Send Email</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row"  *ngIf="modalType == 'pay'">
        <p>Please pay the amount of <b>₹  {{selectedSeva.price}}</b>  to complete your order</p>
        <img src="./assets/image/amawaqr.png">
      </div>
    </div>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Closed')">Close</button>
  </div>
</ng-template>