import { Component, OnInit } from '@angular/core';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import * as $ from 'jquery';
import {AppConfig} from '../../assets/config/configuration';

@Component({
  selector: 'app-prasadam',
  templateUrl: './prasadam.component.html',
  styleUrls: ['./prasadam.component.scss'],
  providers: [HttpClient, NgbModalConfig, NgbModal]
})
export class PrasadamComponent implements OnInit {
  rateChart: any;
  groceries: any[];
  onekgQuantity: number = 0;
  onekgTotal: number = 0;
  halfkgQuantity: number = 0;
  halfkgTotal: number = 0;
  totalSummary: number = 0;
  closeResult = '';
  message: string = '';
  prasadam : string = AppConfig.PRASADAM;
  mailRequest: any;
  baseUrl: string;
  selectedSeva: any;
  modalType: string = 'mail';
  constructor(
    config: NgbModalConfig,
    private modalService: NgbModal,
    private http: HttpClient
  ) {
    config.backdrop = 'static';
    config.keyboard = false;
    this.groceries = [{
      id: 0, label: 'Butter'
    }, {
      id: 1, label: 'Apples'
    }, {
      id: 2, label: 'Paprika'
    }, {
      id: 3, label: 'Potatoes'
    }, {
      id: 4, label: 'Oatmeal'
    }, {
      id: 5, label: 'Spaghetti'
    }, {
      id: 6, label: 'Pears'
    }, {
      id: 7, label: 'Bacon'
    }];
    this.mailRequest = {
      name: '',
      phone: '',
      email: '',
      subject: 'Laddo Order',
      message: '',
      address : ''
    };
    this.baseUrl = 'https://ayodhyamawamandir.org/mailer/mail.php';
  }


  public open(content) {
    this.modalType = 'mail';
    let summary;
    summary = this.onekgQuantity + ' * 1kg & ' + this.halfkgQuantity + ' * 500gms';
    this.mailRequest.message = 'An order has been placed for ' + summary + ' for amount of ₹ ' + this.totalSummary;
    this.modalService.open(content);
  }
  ngOnInit(): void {
    this.rateChart = [
      {
        id: 1,
        size: '250gms',
        rate: 'INR 75.00',
        quantity: 0
      },
      {
        id: 2,
        size: '500gms',
        rate: 'INR 150.00',
        quantity: 0
      },
      {
        id: 3,
        size: '1Kg',
        rate: 'INR 75.00',
        quantity: 0
      },
      {
        id: 4,
        size: '1 Piece',
        rate: 'INR 15.00',
        quantity: 0
      },
    ];
  }
  public updateAmount() {
    this.onekgQuantity = (this.onekgQuantity < 0 ) ? 0 : this.onekgQuantity;
    this.halfkgQuantity = (this.halfkgQuantity < 0 ) ? 0 : this.halfkgQuantity;
    this.onekgTotal = this.onekgQuantity * 300;
    this.halfkgTotal = this.halfkgQuantity * 150;
    this.totalSummary = this.onekgTotal + this.halfkgTotal;
  }
  public submitForm(){
    this.modalType = 'pay';
    this.mailRequest.message = this.mailRequest.message + ' at address ' +  this.mailRequest.address;
    $.ajax({
      url: 'mailer/mail.php',
      method: 'POST',
      dataType: 'json',
      data: {
        name: this.mailRequest.name,
        email: this.mailRequest.email,
        subject: this.mailRequest.subject,
        message: this.mailRequest.message,
        phone : this.mailRequest.phone
      }, success: function (response) {
        if (response.status == 'success'){
        }else {
          alert('Unable to send email. Please try again.');
        }
      }
    });
    // this.http.post(this.baseUrl, this.mailRequest)
    //     .subscribe(
    //         response => {
    //           this.modalType = 'pay';
    //           console.log('response' + response);
    //         },
    //         error => {
    //           console.log('error' + error);
    //         }
    //     );
  }

}
