<!-- About Section -->
<section class="page-section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <h2 class="mt-0">Raghupati Ladoo Prasadam</h2>
        <hr class="divider light my-4">
        <div class="slides">
          <div class="col-xs-12">
            <p>
              {{prasadam}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- About Section -->
<section class="page-section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <h2 class="mt-0">Order Online</h2>
        <hr class="divider light my-4">
        <div class="">
          <div class="col-xs-12">
            <div class="flexbox">
              <div class="card">
                <div class="card-header">
                  Laddo
                </div>
                <div class="card-image">
                  <img src="./assets/image/prasad.jpg">
                </div>
              </div>
              <div class="order-wrapper">
                <div class="order-row">
                  <div>1Kg Pack(₹ 300.00)</div>
                  <div class="order-quantity">
                    <div (click)="onekgQuantity = onekgQuantity - 1;updateAmount()">-</div>
                    <div>{{onekgQuantity }}</div>
                    <div (click)="onekgQuantity = onekgQuantity + 1;updateAmount()">+</div>
                  </div>
                  <div>
                    ₹ {{onekgTotal}}
                  </div>
                </div>
                <div class="order-row">
                  <div>500gms Pack(₹ 150.00)</div>
                  <div class="order-quantity">
                    <div (click)="halfkgQuantity = halfkgQuantity - 1;updateAmount()">-</div>
                    <div>{{halfkgQuantity }}</div>
                    <div (click)="halfkgQuantity = halfkgQuantity + 1;updateAmount()">+</div>
                  </div>
                  <div>
                    ₹ {{halfkgTotal}}
                  </div>
                </div>
                <div class="order-row">
                  <div><b>Total</b></div>
                  <div>
                    ₹ {{totalSummary}}
                  </div>
                </div>
                <div class="order-row">
                  <button [disabled]="(onekgQuantity < 1) && (halfkgQuantity < 1)"  (click)="open(content)">Order Now</button>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Thanks </h4>
    <!--<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>-->
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row" *ngIf="modalType == 'mail'">
        <div class="col-md-12">
          <div class="well well-sm">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="name">
                    Name</label>
                  <input type="text" class="form-control" id="name" [(ngModel)]="mailRequest.name" placeholder="Enter name"
                    required="required" />
                </div>
                <div class="form-group">
                  <label for="name">
                    Phone</label>
                  <input type="number" class="form-control" id="phone" [(ngModel)]="mailRequest.phone" placeholder="Phone Number"
                    required="required" />
                </div>
                <div class="form-group">
                  <label for="email">
                    Email Address</label>
                  <div class="input-group">
                    <span class="input-group-addon"><span class="glyphicon glyphicon-envelope"></span>
                    </span>
                    <input type="email" [(ngModel)]="mailRequest.email" class="form-control" id="email" placeholder="Enter email"
                      required="required"/>
                  </div>
                </div>
                <div class="form-group" style="display: none;">
                  <label for="subject">
                    Ritual</label>
                    <input type="text" [(ngModel)]="mailRequest.subject" class="form-control" id="subject" placeholder="Enter ritual"
                      required="required"/>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                    <label for="name">
                        Address</label>
                    <textarea [(ngModel)]="mailRequest.address" id="message" class="form-control" rows="2" cols="25" required="required"
                        placeholder="Message"></textarea>
                </div>
              </div>
              <div class="col-md-12" style="display: none;">
                <div class="form-group">
                    <label for="name">
                        Message</label>
                    <textarea [(ngModel)]="mailRequest.message" id="message" class="form-control" rows="2" cols="25" required="required"
                        placeholder="Message"></textarea>
                </div>
              </div>
              <div class="col-md-12">
                <button [disabled]="!mailRequest.name || !mailRequest.phone || !mailRequest.email || !mailRequest.address" 
                (click)="submitForm()"  class="btn btn-primary pull-right" id="btnContactUs">
                  Send Email</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="modalType == 'pay'">
        <p>Thanks for ordering with us. We will contact you soon</p>
        <!-- <img src="./assets/image/amawaqr.png"> -->
      </div>
    </div>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Closed')">Close</button>
  </div>
</ng-template>