import { Component, OnInit } from '@angular/core';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import * as $ from 'jquery';
import {AppConfig} from '../../assets/config/configuration';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss'],
  providers: [HttpClient, NgbModalConfig, NgbModal]
})
export class ServicesComponent implements OnInit {
  closeResult = '';
  rituals: any[] = [];
  selectedRitual: object;
  message: string;
  mailRequest: any;
  baseUrl: string;
  selectedSeva: any;
  modalType: string = 'mail';
  ritualDescription : string = AppConfig.RITUALS;
  constructor(
    config: NgbModalConfig,
    private modalService: NgbModal,
    private http: HttpClient
  ) {
    config.backdrop = 'static';
    config.keyboard = false;
    this.rituals = AppConfig.RITUALSLIST;
    this.selectedRitual = {
      name: '',
      price: ''
    };
    this.message = '';
    this.mailRequest = {
      name: '',
      phone: '',
      email: '',
      subject: '',
      message: ''
    };
    this.baseUrl = 'https://ayodhyamawamandir.org/mail.php';
  }
  ngOnInit(): void {
  }
  public open(ritual, content) {
    this.modalType = 'mail';
    this.selectedSeva = ritual;
    this.mailRequest.subject = ritual.name;
    this.mailRequest.message = 'An order has been placed for ' + ritual.name + ' for amount of ' + ritual.price;
    this.modalService.open(content);
  }
  public submitForm() {
    this.modalType = 'pay';
    $.ajax({
      url: 'mailer/mail.php',
      method: 'POST',
      dataType: 'json',
      data: {
        name: this.mailRequest.name,
        email: this.mailRequest.email,
        subject: this.mailRequest.subject,
        phone: this.mailRequest.phone,
        message: this.mailRequest.message
      }, success: function (response) {
        if (response.status == 'success'){
        }else {
          alert('Unable to send email. Please try again.');
        }
      }
    });
    // this.http.post(this.baseUrl, this.mailRequest)
    //     .subscribe(
    //         response => {
    //           this.modalType = 'pay';
    //           console.log('response' + response);
    //         },
    //         error => {
    //           console.log('error' + error);
    //         }
    //     );
  }
}
