import { Component, OnInit } from '@angular/core';
import {AppConfig} from '../../assets/config/configuration';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {
  rasoidesc: string;
  constructor() {
    this.rasoidesc =AppConfig.RAMRASOI;
  }

  ngOnInit(): void {
  }

}
